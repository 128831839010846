import Fingerprint from "fingerprintjs2";
import log from "@/utils/logger";
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import tools from "@/utils/tools";
import { bus, busName } from "@/components/core/bus";
import native from "@/components/core/native.js";

/**
 * lobby/info:
 {
  "DynamicPicture": 
}
 */
export const global = {
  namespaced: true,
  state: {
    uuid: '',
    lobbyInfo: {
      ActivityConf: {
        "Bonus": [],
        Rebate: []
      },
      CustomerInfo: {
        customerFaceBook: "facebook",
        customerLiveChat: "liveChat",
        customerMail: "mail",
        customerMessenger: "messenger",
        customerOnline: "http://google.com",
        customerTel: ['+855 110110', '+855 110112'],
        customerTelegram: "@vn_poker",
        customerZalo: "zalo",
      },
      FirstPageBanner: [
        {
          "DetailUrl": "",
          "DetailUrlP": "",
          "Factory": "",
          "GameCode": "",
          "PType": "LiveCasino",
          "PUrl": "",
          "Sort": 1,
          "TitleUrl": "",
          "TitleUrlP": ""
        }
      ],
      GuessLove: [
        {
          "PUrl": "",
          "GameType": "Slot",
          "GameName": "STARRY WITCH",
          "GameCode": "AWS_45",
          "FactoryName": "apiAeGame",
          "Hot": 0,
          "New": 0
        }
      ],
      HotDomain: "http://cdn.combine.vngame888.com",
      HotMatch: [
        {
          "HomeTeamID": 285335,
          "HomeTeamName": "Rare Atom",
          "HomeImgUrl": "https://res.cloudinary.com/dvslkeaha/image/upload/v1614491148/fv6lmf7cxo1dwxevm4un.png",
          "AwayTeamID": 82607,
          "AwayTeamName": "OMG",
          "AwayImgUrl": "https://res.cloudinary.com/dvslkeaha/image/upload/v1622545986/hby2s0wn6vhnwymv90ve.png",
          "MatchDate": "2022-06-16 17:00:00",
          "SportName": "E-Sports"
        }
      ],
      HotRecommend: [
        {
          "Oid": "61d2f43ebf8700a5cacf6180",
          "PlatformType": "PC",
          "TemplateType": "",
          "Catalog": "Common",
          "Column": "HotRecommend",
          "PType": "Card",
          "PUrl": "https://testf.88bet88bet.com/group1/combine/picture/20220416/14/13/2/d3df8f698303a41e023ce8d9f12924da.webp",
          "Factory": "apiSpadeGame",
          "GameCode": "",
          "Sort": 1,
          "Remark": "热门推荐棋牌",
          "UpdateAt": "2022-05-15T16:26:58.939Z",
          "CreateAt": "2022-01-03T13:00:11.826Z"
        }
      ],
      LobbyNotice: [],
      RankList: [],
      VersionGet: {
        "android": {
          "VersionName": "1.0",
          "VersionCode": 1,
          "Msg": "发现新版本",
          "Platform": "android",
          "UrlPath": "https://google.com",
          "AppKey": "vnFirstPPdwckd",
          "Size": "20.8M",
          "CreateAt": "2022-01-18T16:55:45.714Z",
          "UpdateAt": "2022-01-22T05:55:58.211Z"
        },
        "ios": {
          "VersionName": "1.0",
          "VersionCode": 1,
          "Msg": "发现新版本",
          "Platform": "ios",
          "UrlPath": "https://google.com",
          "AppKey": "vnFirstPPdwckd",
          "Size": "10.8MB",
          "CreateAt": "2022-01-18T16:55:45.733Z",
          "UpdateAt": "2022-01-22T05:55:50.208Z"
        }
      },
      StaticPicture: {

      },
      FactoryList: [
        {
          "Factory": "all",
          "vi": "Tất Cả",
          "zh": "全部",
          "en": "全部"
        }
      ],
      hasInit: false,
    },
    maintenaText: "2022-18-06 18:00:00 ~\nThông báo sau khi hoàn tất",
    xappVersion: "",
    compileTime: "",
  },
  mutations: {
    setUuid(state, uuid) {
      state.uuid = uuid
    },
    setLobbyInfo(state, lobbyInfo) {
      state.lobbyInfo = lobbyInfo
      state.lobbyInfo.hasInit = true
      bus.emit(busName.lobbyInfo)
    },
    setXappVersion(state, version) {
      state.xappVersion = version
    },

    setCompileTime(state, time) {
      state.compileTime = time
    },
  },
  actions: {
    init({ commit, dispatch, rootGetters, getters }) {
      return new Promise((resolve, reject) => {
        let uuid = getters['uuid'];
		console.log('fffffffsd')
		
        if (uuid != '') return resolve(uuid);
        Fingerprint.get(function (components) {
          const values = components.map(function (component, index) {
            if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
              return component.value.replace(/\bNetType\/\w+\b/, '')
            }
            return component.value
          })
          // 生成最终id murmur
          const murmur = Fingerprint.x64hash128(values.join(''), 31)
          log.info('uuid:', murmur)
          commit("setUuid", murmur)
          if (tools.isNative())
            dispatch("reqXappVersion")
          resolve(murmur)
        })
      })
    },
    reqXappVersion({ commit, dispatch, rootGetters, getters }) {
      native.getCompileTime().then(data => {
        commit("setXappVersion", data)
      })
    },
    formatNumber(str) {
      if (!str.length) {
        return ''
      }
      let nums = parseInt(num)
      var res = nums.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
          return $1 + ",";
        });
      })
      return res;


    },

    getLobbyInfo({ commit, dispatch, rootGetters, getters }) {
      let lobbyInfo = getters["lobbyInfo"];
      return new Promise(async (resolve, reject) => {
        if (lobbyInfo.hasInit == false) {
          let params = {
            platformType: tools.platform(),
            StaticPicture: "",
            LobbyNotice: "",
            FirstPageBanner: "",
            HotMatch: "",
            HotRecommend: "",
            GuessLove: "",
            Partner: "",
            LoginPoster: "",
            Business: "",
            LobbyStartPage: "",
            LobbyBackGround: "",
            LobbySharePoster: "",
            RankList: "",
            CustomerInfo: "",
            FactoryList: "",
            GameTypeList: "",
            ActivityConf: "",
            HotDomain: "",
            VersionGet: "",
			ActivityVipConf:"",
			ActivityMemberConf:"",
			CasinoRecommend:""
          }
          let rsp = await req.post(http.lobbyConf, qs.stringify(params));
          if (rsp.status == 200 && rsp.data.Code == 0) {
            // this.onLogged(rsp.data.Data);
            log.info(rsp.data.Data);
            // console.log(JSON.stringify(rsp.data.Data))
            commit("setLobbyInfo", rsp.data.Data)
            resolve(rsp.data.Data)
          } else {
            setTimeout(function () {
              dispatch("getLobbyInfo").then(resolve, reject)
            }, 5000)
            // reject(rsp.data)
          }
        } else {
          resolve(lobbyInfo)
        }
      })
    },

  },
  getters: {
    maintenaText(state) {
      return state.maintenaText
    },
    globalState(state) {
      return state
    },
    uuid(state) {
      return state.uuid
    },
    lobbyInfo(state) {
      return state.lobbyInfo
    },
    curVersion(state) {
      let platform = tools.platform()
      if (platform == "h5_android") {
        return state.lobbyInfo.VersionGet.android
      } else if (platform == "h5_ios") {
        return state.lobbyInfo.VersionGet.ios
      } else {
        return false
      }
    },
    staticPic(state) {
      return state.lobbyInfo.StaticPicture
    },
    factoryList(state) {
      return function (type, language) {
        let res = [];
        for (let i in state.lobbyInfo.FactoryList) {
          let item = state.lobbyInfo.FactoryList[i];
          if (item.Factory == "all") continue;
          let check = false
          if (type == "all") {
            check = true
          } else {
            let arr = item.Factory.split("/");
            if (arr[1] == type) {
              check = true
            } else {
              check = false
            }
          }
          res.push({
            id: item["Factory"],
            title: item[language],
            check: check,
          });
        }
        return res;
      }
    },
    factoryCat(state) {
      return function (type) {
        const imgs = ''//require("./factory_icon.js").default
        let res = []
        for (let k in state.lobbyInfo.StaticPicture[type]) {
          let item = state.lobbyInfo.StaticPicture[type][k]
          if (typeof imgs[type][k] == "undefined") {
            console.error("not found type:" + type + ", k: " + k)
          }
          let ret = {
            value: k,
            img: imgs[type][k].img,
            imgAct: imgs[type][k].imgAct,
            maintena: item.Factory.Status != 1,
            status: item.Factory.Status,
            gameList: item.GameList,
            Sort: item.Factory.Sort
          }
          res.push(ret)
        }
		res = res.sort((a, b) => {
			
			return  a.status - b.status||b.Sort- a.Sort
			
		})
        return res
      }
    },
    version(state) {
      let v = state.compileTime
      if (tools.isNative()) {
        v += "_" + state.xappVersion
      }
      return v
    },
    liveChatId(state){
      let ret = false
      let str = state.lobbyInfo.CustomerInfo.customerLiveChat
      let arr = str.split("/")
      if (arr.length >=3){
        ret =  arr[3]
      }
      // console.log("liveChatId: " + ret)
      return ret
    }
  }
}
