import { getCurrentInstance } from 'vue'
import numeral from 'numeral'
//import useClipboard from "vue-clipboard3";
import { Toast } from 'vant';
let tools = {
    datePipeTs: function (timestamp, format = 'yyyy-MM-dd hh:mm:ss') {
        let date = new Date(timestamp);
        return this.datePipe(date, format)
    },
    datePipeStr: function (str, format = 'yyyy-MM-dd hh:mm:ss') {
        let date = new Date(Date.parse(str));
        return this.datePipe(date, format)
    },
    datePipe: function (date, format = 'yyyy-MM-dd hh:mm:ss') {
        if (isNaN(date)) {
            date = new Date();
        }
        var o = {
            'y+': date.getFullYear(),
            'M+': date.getMonth() + 1, // 月份 "d+": value.getDate(), // 日
            'd+': date.getDate(),
            'h+': date.getHours(), // 小时 "m+": value.getMinutes(), // 分 "s+": value.getSeconds(), // 秒
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(
                    RegExp.$1,
                    RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
                );
            }
        }
        return format;
    },
    platform: function () {
        let u = navigator.userAgent;
        if (typeof xapp != "undefined") {
            if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
                return "android"
            } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
                return "ios"
            } else {
                return "native"
            }
        } else if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
            return "h5_android"
        } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            return "h5_ios"
        } else {
            return "web"
        }
    },
    getQueryVariable: function (variable) {
        let href = window.location.href
        let query = href.substring(href.indexOf('?') + 1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    },
    isNative() {
        return typeof xapp != "undefined"
    },
    isMobile() {
        if (this.getQueryVariable("mm") == '1') return true;
        return this.platform() == "h5_android" || this.platform() == "h5_ios" || this.isNative()
    },
    blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    },
    timestampMillisec() {
        var timestamp2 = (new Date()).valueOf();
        return timestamp2
    },
    formatNum2k(num) {
        num = num / 1000;
        return numeral(num).format('0,0.000').substr(0,numeral(num).format('0,0.000').length-1);
    },
	formatNum3k(num) {
	    num = num / 1000;
	    return numeral(num).format('0,0.000');
	},
    formatNum(num) {
        return numeral(num).format('0,000');
    },
    formatMoney(num) {
        return this.numberUnit(num, 2, false)
    },
    numberUnit(num, digits, needObj = true) {
        const si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "K" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "G" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        if (needObj)
            return { value: (num / si[i].value).toFixed(digits).replace(rx, "$1"), symbol: si[i].symbol };
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    },
    copyText(text) {
        const { toClipboard } = useClipboard();
        return toClipboard(text);
    },
    toast(text) {
        Toast(text)
    },
    loading() {
        Toast.loading({
            duration: 0,
            message: 'loading...',
            forbidClick: true,
        });
    },
    dismissLoading(all = true) {
        Toast.clear(all)
    },
    systemLanguage() {
        let lang = navigator.language || navigator.userLanguage
        return lang.substr(0, 2)
    },
    formatDate(str, format = "yyyy-MM-dd hh:mm"){
       return new Date(Date.parse(str)).Format(format)
    },
    isRelease(){
        return process.env.NODE_ENV == "production"
    },
    winWidth(){
        return document.documentElement.clientWidth
    },
}



export default tools
