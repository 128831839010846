<template>
	<mobile v-if="_isMobile()"></mobile>
	<pc v-else style="" ></pc>

</template>



<script>
	import pc from './pc.vue'
	import mobile from './mobile.vue'

	export default {
		name: 'app',
		components: {
			pc,
			mobile
		},
		provide () {
		   return {
					  scale:1
		   }
		 },
		 created(){
			 var that = this
				const bodyScale = () => {
					let deviceWidth = document.documentElement.clientWidth
					let scale = deviceWidth / 1920
					that.scale = scale
					//console.log('fff',that.scale)
					
				}
				bodyScale()
				window.onresize = () => {
					return (() => {
						bodyScale()
					})()
				}
		 },
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			}

		}
	}
</script>

<style lang="scss">
	html, body {
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  background-color: white
	}
	
</style>
