<template>
	<div style="position:relative;overflow: hidden;height: 100vh;">
		<img src="./assets/bg_zhuce.png"   alt="" class="bg" style="width:100%;height: 100vh;">
		
		<div class="wrap" :style="'scale:'+scale">
			<div style="position: relative;">
				<img src="./assets/bg_zcy_db.png" v-if='selectIndex==0' alt="" style="width: 100%;">
				
				<img src="./assets/zc_bg.png" v-else alt="" style="width: 100%;">
				<div style='position: absolute;left: 0;top:0;width: 100%;'>
					<div style="margin-left: 89.5px;margin-top: -10px;">
						<img src="./assets/btn_dangky1.png" v-if="selectIndex==1" alt="" @click="selectIndex=0" style="width: 283px;cursor: pointer;">
						<img src="./assets/btn_dangky2.png" v-else alt="" style="width: 283px;cursor: pointer;">
						
						<img src="./assets/btn_dangnhap1.png" v-if="selectIndex==0" alt="" @click="selectIndex=1" style="width: 283px;cursor: pointer;">
						<img src="./assets/btn_dangnhap2.png" v-else alt="" style="width: 283px;cursor: pointer;">
						
					</div>
					
					<div style="position: relative;margin: 10px auto;width:522px;text-align: center;" v-if="selectIndex==0">
						<div style="position: relative;">
							<img src="./assets/dk_shurukuang_xz.png" v-if='inputIndex==0' alt="" style='width:522px;'>
							<img src="./assets/dk_shurukuang.png"  v-else alt="" style='width:522px;'>
							
							<div style="padding-left: 25px;position: absolute;left: 0;top:-3px;height: 100%;width: 100%;display:flex;flex-direction: row;align-items: center;">
								<img src="./assets/user2.png" v-if='inputIndex==0'  alt="" style='width:33px;'>
								<img src="./assets/user.png" v-else  alt="" style='width:33px;'>
								
								<input type="text" v-model="account" placeholder="Tên đăng nhập" @focus="inputIndex=0">
							</div>
							
										
							
						</div>
						
						<div style="position: relative;margin-top: 20px;">
							<img src="./assets/dk_shurukuang_xz.png" v-if='inputIndex==1' alt="" style='width:522px;'>
							<img src="./assets/dk_shurukuang.png"  v-else alt="" style='width:522px;'>
							
							<div style="padding-left: 25px;position: absolute;left: 0;top:-3px;height: 100%;width: 100%;display:flex;flex-direction: row;align-items: center;">
								<img src="./assets/padlock2.png" v-if='inputIndex==1'  alt="" style='width:25px;'>
								<img src="./assets/padlock1.png" v-else  alt="" style='width:25px;'>
								
								<input :type="pw1CanSee?'text':'password'" v-model="pw" placeholder="Mật khẩu" @focus="inputIndex=1">
							</div>
							<img @click="pw1CanSee=!pw1CanSee" src="./assets/eyecopy2.png" v-if='inputIndex==1'  alt="" style='width:32px;position: absolute;right:30px ;top: 20px;cursor: pointer;'>
							<img @click="pw1CanSee=!pw1CanSee" src="./assets/eyecopy1.png" v-else  alt="" style='width:32px;position: absolute;right:30px ;top: 18px;cursor: pointer;'>
							
						</div>
						
						<div style="position: relative;margin-top: 20px;">
							<img src="./assets/dk_shurukuang_xz.png" v-if='inputIndex==2' alt="" style='width:522px;'>
							<img src="./assets/dk_shurukuang.png"  v-else alt="" style='width:522px;'>
							
							<div style="padding-left: 25px;position: absolute;left: 0;top:-3px;height: 100%;width: 100%;display:flex;flex-direction: row;align-items: center;">
								<img src="./assets/padlock2.png" v-if='inputIndex==2'  alt="" style='width:25px;'>
								<img src="./assets/padlock1.png" v-else  alt="" style='width:25px;'>
								
								<input :type="pw2CanSee?'text':'password'" v-model="pw2" placeholder="Nhập lại mật khẩu" @focus="inputIndex=2">
							</div>
							<img @click="pw2CanSee=!pw2CanSee" src="./assets/eyecopy2.png" v-if='inputIndex==2'  alt="" style='width:32px;position: absolute;right:30px ;top: 20px;cursor: pointer;'>
							<img @click="pw2CanSee=!pw2CanSee" src="./assets/eyecopy1.png" v-else  alt="" style='width:32px;position: absolute;right:30px ;top: 18px;cursor: pointer;'>
							
						</div>
						<div style="position: relative;margin-top: 20px;">
							<img src="./assets/dk_shurukuang_xz.png" v-if='inputIndex==3' alt="" style='width:522px;'>
							<img src="./assets/dk_shurukuang.png"  v-else alt="" style='width:522px;'>
							
							<div style="padding-left: 25px;position: absolute;left: 0;top:-3px;height: 100%;width: 100%;display:flex;flex-direction: row;align-items: center;">
								<img src="./assets/phone2.png" v-if='inputIndex==3'  alt="" style='width:19px;'>
								<img src="./assets/phone.png" v-else  alt="" style='width:19px;'>
								
								<input type="text" v-model="phone" placeholder="Số điện thoại" @focus="inputIndex=3">
							</div>
						</div>
						
						<div style="position: relative;margin-top: 20px;">
							<img src="./assets/dk_shurukuang_xz.png" v-if='inputIndex==4' alt="" style='width:522px;'>
							<img src="./assets/dk_shurukuang.png"  v-else alt="" style='width:522px;'>
							
							<div style="padding-left: 25px;position: absolute;left: 0;top:-3px;height: 100%;width: 100%;display:flex;flex-direction: row;align-items: center;">
								<img src="./assets/p_code.png" v-if='inputIndex==4'  alt="" style='width:19px;'>
								<img src="./assets/p_code2.png" v-else  alt="" style='width:19px;'>
								
								<input type="text"  v-model="code" placeholder="MÃ" @focus="inputIndex=4">
							</div>
							<img :src="codeImg" @click="getCodeImg()" alt="" :style="inputIndex==4?'top:5px;':''" style="cursor: pointer;height: 53px;right:30px;position: absolute;background-color: white;">
							
						</div>
						
						<div class='btn' style="position: relative;width: 448px;margin-left:33px;margin-top: 20px;cursor: pointer;" @click="register()">
							<img src="./assets/btn_danky.png" alt="" style="width: 448px;">
							<img src="./assets/88Kcopy.png" alt="" style="width: 105px;position: absolute;right: 50px;top: 23px;">
							
						</div>
						
					</div>
					<div v-else style="margin: 10px auto;width:522px;">
						<div style="position: relative;">
							<img src="./assets/dk_shurukuang_xz.png" v-if='inputIndex1==0' alt="" style='width:522px;'>
							<img src="./assets/dk_shurukuang.png"  v-else alt="" style='width:522px;'>
							
							<div style="padding-left: 25px;position: absolute;left: 0;top:-3px;height: 100%;width: 100%;display:flex;flex-direction: row;align-items: center;">
								<img src="./assets/user2.png" v-if='inputIndex1==0'   alt="" style='width:33px;'>
								<img src="./assets/user.png" v-else  alt="" style='width:33px;'>
								
								<input v-model='accountL' type="text" placeholder="Tên đăng nhập" @focus="inputIndex1=0" >
							</div>
						</div>
						
						<div style='margin-top:20px;position: relative;'>
							<img src="./assets/dk_shurukuang_xz.png" v-if='inputIndex1==1' alt="" style='width:522px;'>
							<img src="./assets/dk_shurukuang.png"  v-else alt="" style='width:522px;'>
							
							<div style="padding-left: 25px;position: absolute;left: 0;top:-3px;height: 100%;width: 100%;display:flex;flex-direction: row;align-items: center;">
								<img src="./assets/padlock2.png" v-if='inputIndex1==1'  alt="" style='width:25px;'>
								<img src="./assets/padlock1.png" v-else  alt="" style='width:25px;'>
								
								<input v-model='pwL' :type="pw3CanSee?'text':'password'" type="password" placeholder="Mật khẩu" @focus="inputIndex1=1">
							</div>
							<img @click="pw3CanSee=!pw3CanSee" src="./assets/eyecopy2.png" v-if='inputIndex1==1'  alt="" style='width:32px;position: absolute;right:30px ;top: 20px;cursor: pointer;'>
							<img @click="pw3CanSee=!pw3CanSee" src="./assets/eyecopy1.png" v-else  alt="" style='width:32px;position: absolute;right:30px ;top: 18px;cursor: pointer;'>
							
						</div>
						
						<img  class='btn' src="./assets/dl.png"  @click="login()" alt="" style='width:448px;margin-top:40px;margin-left:30px;cursor: pointer;'>
						
						
					</div>
				</div>
				
			</div>
			
		</div>
		
	</div>
	
</template>

<script>
	import  tools from "@/utils/tools";
	
	import {
		http
	
	} from "@/components/protocol/api";
	import {
		req,
		qs
	} from "@/utils/request";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	
	export default {
		data(){
			return {
				selectIndex:0,
				inputIndex:-1,
				inputIndex1:-1,
				uuidWeb:'',
				accountL:'',
				pwL:'',
				codeImg:'',
				imgId:'',
				account:'',
				pw:'',
				pw2:'',
				phone:'',
				code:'',
				pw1CanSee:false,
				pw2CanSee:false,
				pw3CanSee:false,
				scale:1
				
			}
		},
		props:{
			
	
		},
		computed: {
		
		
			
		
		},
		created() {
			let deviceWidth = document.documentElement.clientWidth
			let scale = deviceWidth / 1920
			this.scale = scale
			this.getCodeImg()
			this.init().then((res) => {
				this.uuidWeb = res;
			});
			
		},
		methods:{
			...mapActions("global", ["init"]),
			getCodeImg() {
				var that = this
				req.post('/captcha', {
			
				}).then(function(res) {
					that.codeImg = res.data.Data.img
					that.imgId = res.data.Data.id
				}).catch(function(err) {
			
				})
			},
			async register() {
				if(!this.account.length){
					this.$message({
						message: 'Tên đăng nhập',
						type: 'error'
					});
					
					return
				}
				if(!this.pw.length){
					this.$message({
						message: 'Mật khẩu',
						type: 'error'
					});
					
					return
				}
				if(!this.pw2.length){
					this.$message({
						message: 'Vui lòng nhập mật khẩu xác nhận',
						type: 'error'
					});
					
					return
				}
				if(this.pw!=this.pw2){
					this.$message({
						message: 'Hai mật khẩu không khớp',
						type: 'error'
					});
					return
				}
				if(!this.phone.length){
					this.$message({
						message: 'Số điện thoại',
						type: 'error'
					});
					
					
					return
				}
			
				
				
				
				let params = {
					account: this.account,
					password: this.pw,
					phone: this.phone,
					platform: tools.platform(),
					channel: "game",
					uuid: this.uuidWeb,
					uuid_web: this.uuidWeb,
					code:this.code,
					codeId:this.imgId,
				};
				var that = this
				let rsp = await req.post('/user/register', qs.stringify(params));
				if (rsp.status == 200 && rsp.data.Code == 0) {
					this.account = ''
					this.pw = ''
					this.pw2 = ''
					this.phone = ''
					this.code = ''
					
					//this.onLogged(rsp.data.Data);
					window.open('https://88bet88bet.com?token='+rsp.data.Data.token.AccessToken)
					
				} else {
					this.$message({
						message: rsp.data.ErrMsg,
						type: 'error'
					});
				}
				
			},
			
			async login(){
				if(!this.accountL.length){
					this.$message({
						message: 'Tên đăng nhập',
						type: 'error'
					});
					return
				}
				if(!this.pwL.length){
					this.$message({
						message: 'Mật khẩu',
						type: 'error'
					});
					return
				}
				//userRegister
				let params = {
					account: this.accountL,
					password: this.pwL,
					platform: tools.platform(),
					uuid: this.uuidWeb,
					uuid_web: this.uuidWeb,
				};
				
				
				
				let rsp = await req.post('/user/login', qs.stringify(params));
				if (rsp.status == 200 && rsp.data.Code == 0) {
					//this.onLogged(rsp.data.Data);
					window.open('https://88bet88bet.com?token='+rsp.data.Data.token.AccessToken)
					
				} else {
					this.$message({
						message: rsp.data.ErrMsg,
						type: 'error'
					});
				}
			},
			
			
		}
	}
</script>

<style scoped>
	body{
		margin: 0;
		padding: 0;
		min-width: 1200px;
	}
	img{
		display: inline-block;
	}
	.wrap{
		position: absolute;		
		width:745px;
		height: 300px;
		z-index: 999;
		display: flex;
		flex-direction: column;
		right:5vw;
		top:calc(50vh - 212px);
	}
	input{
		height:72%;
		
		width: 400px;
		font-size: 20px;
		margin-left:5px;
		color:white;
	}
	input:focus{
		color:#36c9ff;
	}
	input:active {
	  background-color: transparent;
	}
	
	input{
		  background-color: transparent;
		  outline: none;
		  border:none;
	}
	
	input {
	  outline: none;
	  -webkit-tap-highlight-color: black;
	  /*-webkit-appearance: none;*/
	}
	.btn:hover{
		scale: 1.1;
	}
</style>