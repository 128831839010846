import Fingerprint from "fingerprintjs2";
import log from "@/utils/logger";
import { req, qs } from "@/utils/request";
import { http, topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { emitter } from '@/utils/bus.js'
import tools from "@/utils/tools.js"

const reconnectTime = 5000
let reconnect = true
let connecting = false
export const tcp = {
    namespaced: true,
    state: {
        isLogged: false,
        inviteUrl: "",
        userInfo: {
            ActivityTotal: 0,
            ChargeCode: "",
            DouDouBet: 0,
            FistChargeTime: "2022-05-04T11:18:08.603Z",
            GiftCode: 0,
            HaveCharge: 1,
            LastChargeTime: "2022-05-04T11:18:08.603Z",
            Oid: "-",
            SafeStatus: 0,
            SumAgentBalance: 0,
            SumBet: 0,
            SumBetCount: 0,
            SumBonus: 0,
            SumCharge: 500000,
            SumDouDou: 0,
            SumOnlineSec: 11734,
            VipLevel: 0,
            WinAndLost: 0
        },
        wallet: {
            AgentBalance: 0,//反水
            BonusBalance: 0,//红利
            Oid: "-",
            SafeBalance: 0,
            UpdateAt: "2022-05-04T11:18:08.591Z",
            VndBalance: 0,
        },
        notice: [
           
        ],
        logging: true,
        basicInfo: {
            Birthday: "1970-01-01T08:00:00+08:00",
            BonusList: [
            ],
            "DouDouBet": 0,
            LastLoginTime: "2022-07-12T17:42:27.773+07:00",
            Phone: 123456789,
            "RealName": "testAccount",
            "RebateList": [],
            "RegisterTime": "2022-04-02T21:27:39.433+07:00",
            "TodayBetAmount": 0,
            "TodayBetNum": 0,
            "TodayIncome": 0,
        },
    },
    mutations: {
        setLogging(state, logging) {
            state.logging = logging
        },
        setLogged(state, logged) {
            state.isLogged = logged
        },
        setLoggedData(state, data) {
            state.inviteUrl = data.inviteUrl
            state.userInfo = data.userInfo
            state.wallet = data.wallet
            state.user = data.user
        },
        setWallet(state, data) {
            state.wallet = data
        },
        setNotice(state, notice) {
            state.notice = notice
        },
        setBasicInfo(state, info) {
            state.basicInfo = info
        },
		setInviteUrl(state, inviteUrl){
			state.inviteUrl = inviteUrl
		}
    },
    actions: {
        requestBasicInfo({ commit, dispatch, rootGetters, getters }) {
            mqant.request(topic.userInfo, {}, function (res, topic, msg) {
                log.info(res)
                // log.info(msg.payloadString)
                if (res.Code == 0) {
                    commit("setBasicInfo", res.Data)
                    commit("setWallet", res.Data.Wallet)
                }
            })
        },
        connect({ commit, dispatch, rootGetters, getters }) {
            if (this.connecting) {
                log.warn("cur connect state is connecting...")
                return
            }
            reconnect = true
            connecting = true
            return new Promise((resolve, reject) => {
                let useSSL = "https:" == document.location.protocol ? true : false;
                let tcpInfo = rootGetters['user/tcpInfo'];
                let token = rootGetters['user/token'];
                if (tcpInfo.TcpHost.indexOf("wss") == 0) {
                    useSSL = true;
                }
                let client_id = rootGetters["global/uuid"]
                log.info("mqant tcp info:" + JSON.stringify(tcpInfo))
                log.info("client_id:" + client_id)
                mqant.init({
                    host: tcpInfo.TcpHost,
                    port: tcpInfo.WssPort,
                    client_id: client_id,
                    useSSL: useSSL,
                    onSuccess: function () {
                        connecting = false
                        reconnect = true
                        log.info("ws onConnected");
                        dispatch("tcp/login", {}, { root: true }).then(succ => {
                            resolve();
                            emitter.emit('onConnected')
                        }, failed => { })
                    },
                    onConnectionLost: function (responseObject) {
                        connecting = false
                        log.warn("ws onConnectionLost:");
						emitter.emit('onDisaConnected')
						
                        log.warn("reconnect:" + reconnect);
                        if (reconnect) {
                            log.info("ws will reconnect in " + reconnectTime / 1000 + " sec...")
                            setTimeout(() => {
                                log.info("ws reconnecting...")
                                dispatch("connect").then(succ => {
                                    resolve();
                                })
                            }, reconnectTime);
                        }
                    },
                    onFailure: function () {
                        connecting = false
                        log.error("ws onFailure:");
                        if (reconnect) {
                            log.info("ws will reconnect in " + reconnectTime / 1000 + " sec...")
                            setTimeout(() => {
                                log.info("ws reconnecting...")
                                dispatch("connect").then(succ => {
                                    resolve();
                                })
                            }, reconnectTime);
                        }
                        // reject();
                    },
                });
            })
        },
        disConnect({ commit, dispatch, rootGetters, getters }) {
            commit("setLogged", false)
            reconnect = false
            connecting = false
            if (mqant.isConnect()) mqant.disConnect();
        },
        login({ commit, dispatch, rootGetters, getters }) {
            let token = rootGetters['user/token'];
            let clientId = token.Oid;
            mqant.on("game/push", function (data2, topic, msg2) {
                if (data2.Code === 302) {
					emitter.emit('logout')
					
                    reconnect = false
                    dispatch("user/doLogout", {}, { root: true })
                }
                log.info("push:" + topic)
                log.info(data2);
                // console.log(msg2.payloadString)
                dispathcPush(commit, dispatch, rootGetters, getters, data2, topic, msg2)
            });
            return new Promise((resolve, reject) => {
                mqant.request(
                    topic.tcpLogin,
                    {
                        uid: clientId,
                        token: token.AccessToken,
                    },
					
                    function (res, topic, msg) {
                        log.info(res)
                        //TODO
                        if (res.Code == 0) {
							
                            commit("setLoggedData", res.Data)
                            commit("setLogged", true)
							emitter.emit('hasLogin')
                            commit("user/setUserInfo", res.Data.user, { root: true })
                        } else if (res.Code == 401) {
                            console.error(res)
                            reconnect = false
                            dispatch("user/doLogout", {}, { root: true })
                        }
                        resolve()
                    }
                );
            })
        },
        requestNotice({ commit, dispatch, rootGetters, getters }) {
            mqant.request(topic.notice, {}, function (res, topic, msg) {
                //console.log('notice:',res)
                // console.log(msg.payloadString)
                if (res.Code == 0) {
                    commit("setNotice", res.Data)
                }
            })
        },

    },
    getters: {
        logging(state) {
            return state.logging
        },
        isConnect(state) {
            return mqant.isConnect()
        },
        isLogged(state) {
            return state.isLogged
        },
        wallet(state) {
            return state.wallet
        },
        info(state) {
            return state.userInfo
        },
        inviteUrl(state) {
            return state.inviteUrl
        },
        notice(state) {
            return state.notice
        },
        basicInfo(state) {
            return state.basicInfo
        },
    }
}
function dispathcPush(commit, dispatch, rootGetters, getters, data, topic, msg) {
    switch (data.GameType) {
        case "all":
            dispatchAllAction(commit, dispatch, rootGetters, getters, data, topic, msg)
            break;
        case "chat":
            log.info(msg.payloadString);
            if (data.Action === "newMessage") {
                that.msgList.unshift(data.msg);
            }
            break;
        case "pay":
            // console.log(msg.payloadString);
            if (data.Action === "HD_giftCode") {
                emitter.emit('giftCodeResult', {
                    data: data
                })
            }
            break;
        case "lobby":
            switch (data.Action) {
				case "HD_NotifyJackpot":
				emitter.emit('showGIF',{
					data:data.Data
				})
                case "HD_login":
                    if (data.Code == 302) {
						emitter.emit('loginOut')
                       // dispatch("user/doLogout", {}, { root: true })
                    }
                    break;
            }
            break;
		case "activity":
		
			if(data.Action=='HD_ReceiveVipGiftAll'){
				emitter.emit('receiveGift',{
					data:data
				})
			}else {
				emitter.emit('receiveGift2',{
					data:data
				})
			}
			if(data.Action=='HD_ReceiveMemberAll'){
				emitter.emit('receiveMemberAll',{
					data:data
				})
			}
		   if(data.Action=='HD_StartTurnTable'){
		   	emitter.emit('HD_StartTurnTable',{
		   		data:data
		   	})
		   }
			break
						

			
			
        default:
        // log.warn(data);
        // console.log(msg2.payloadString)
    }
}

function dispatchAllAction(commit, dispatch, rootGetters, getters, data, topic, msg) {
    switch (data.Action) {
        case "wallet":
            commit("setWallet", data.Wallet)
            break;
    }
}